<template lang="pug">
v-container(v-if='location.template')
  v-layout(row)
    v-flex(xs8)
      ScreenNotes
    v-flex(xs4).pl-3
      v-img(:src='design.image.url')
  v-layout(row).mt-3
    v-flex
      FwdCard
        template(slot="title") screens: {{location.template.name}}
        template(slot="content")
          ScreenList(:location='location')
  v-layout(row v-if="file.url")
    v-flex()
      FwdCard
        template(slot="title") file
        template(slot="content")
          span.font-weight-bold rip file:
          a(:href="file.url") {{file.name }}
          span(v-if="file.notes")  - {{file.notes}}
  v-layout.mt-3(row)
    v-flex
      FwdCard
        template(slot="title") progress
        template(slot="content")
          v-checkbox(
            v-model="isScreensPrepped"
            label="Screens prepped?"
            @change="save"
          )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Models from '@/models'
import ScreenList from '@/modules/designs/components/color-list'
import ScreenNotes from '@/modules/designs/components/notes/notes-screen'

export default {
  components: {
    ScreenList,
    ScreenNotes
  },
  computed: {
    ...mapFields('productionInstances', {
      item: 'item',
      isScreensPrepped: 'item.progress.isScreensPrepped',
      file: 'item.ripFile'
    }),
    design () { return this.$store.getters['designs/item'] },
    location () {
      const item = this.design
      return item.locations.find(loc => loc._id === this.item.location._id)
    },
    

  },
  methods: {
    save () { this.$store.dispatch('productionInstances/_UPDATE') }
  },
}
</script>
