<template lang="pug">
div
  ScreenTicket
  
</template>

<script>
import ScreenTicket from './ScreenTicket'

export default {
  components: { ScreenTicket }
}
</script>
