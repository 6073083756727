<template lang="pug">
v-container
  v-layout.headline(column)
    template(v-for="(productGroup, i) in productGroups")
      v-layout.pa-3.mt-5(row  :key="i")
        v-flex
          v-layout(row)
            v-flex(xs1)
              v-img.black-border(
                v-if="productGroup.product.image.url"
                aspect-ratio=1
                height="100px"
                width="100px"
                :src="productGroup.product.image.url"
              )
            v-flex(pl-3).display-1.font-weight-thin.pa-2 {{productGroup.product.displayName}}
          LineItems.mb-3.mt-2(
            :lines="removeZeroQuantityLineItems(productGroup.lineItems)"
            :subtotal="productGroup.subtotal"
            :quantity="productGroup.quantity"
            readOnly=true
          )

</template>

<script>
import LineItems from '../line-items'

export default {
  components: {
    LineItems
  },
  computed: {
    productGroups () {
      return this.$store.getters['productGroups/items']
     },
  },
  methods: {
    removeZeroQuantityLineItems(lineItems) {
      return lineItems.filter(line => {
        return line.quantity !== 0
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.black-border
  border solid black 1px
.thick-left-border
  border-left solid black 2px
</style>
