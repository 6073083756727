<template lang="pug">
div
  DetailFull(:detail="true" v-if="loaded")
    template(slot="title-prefix")
      | Screen Order: 
    template(slot="title")
      |  {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} - 
      span.font-weight-bold {{item.location.template.name}}
    template(slot="controls")
      Controls
    template(slot="form")
      FwdTabs(
        v-model="tabs"
        :items="tabItems"
      )
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailFull from '@/components/base/detail-full-page'
import ScreenOrder from './screen-order'
import Controls from './controls'
import Actions from './actions'
import Mockup from '@/modules/designs/components/document/index'
import Products from '@/modules/jobs/components/summary'
import Reject from './reject'


export default {
  components: {
    DetailFull,
    Controls,
    Actions
  },
  data: () => ({
    loaded: false,
    tabItems: [
      {title: 'screen order', component: ScreenOrder },
      {title: 'mockup', component: Mockup },
      {title: 'products', component: Products },
      {title: 'reject', component: Reject },
    ]
  }),
  computed: {
    
    item () {
      return this.$store.getters['productionInstances/item']
    },
    job () {
      return this.$store.getters['jobs/item']
    },
    ...mapFields('dashboards', {
      actions: 'actions',
      tabs: 'tabs'
    }),
    
  },
  async created () {
    await this.$store.dispatch('productionInstances/_READ_FROM_DB', this.$route.params.id)
    
    this.loaded = true
  }
}
</script>
