<template lang="pug">
div
  v-container
    h1 reject this rip back to art with a comment
    v-layout(row)
      v-flex type: rip request for change - revise rip
    v-layout(row)
      v-flex(xs12 sm8 md6)
        v-text-field(
          label="subject"
          v-model="form.subject"
        )
    v-layout(row)
      v-flex(xs12 sm8 md6)
        v-textarea(
          label="comment"
          v-model="form.text"
        )
    v-btn(@click='reject') reject with this comment

  CommentList(:items='items' v-if='items.length > 0')
        
</template>

<script>
import { Comment } from '@/models'
import { mapFields } from 'vuex-map-fields'
import CommentList from  '@/components/base/comment-list'

export default {
  data: () => ({
    form: new Comment({
      subject: '',
      text: '',
      type: 'rip request for change - revise rip'
    }),
  }),
  computed: {
    ...mapFields('productionInstances', {
      items: 'item.comments',
      isArtRipped: 'item.progress.isArtRipped',
      ripfile: 'item.ripFile',
      priority: 'item.priority'
    })
  },
  methods: {
    async reject () {
      this.form.time = Date.now()
      this.items.push(this.form)
      this.isArtRipped = false
      this.priority = 1
      this.ripfile = {}

      await this.$store.dispatch('productionInstances/_UPDATE')
      this.$router.push('/dashboards/screens')
    }
  },
  components: { CommentList }
}
</script>
